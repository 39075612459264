import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  useCreateEventMutation,
  useGetEventsQuery,
  useUpdateEventMutation,
} from "../../store/services/events";
import { yupConstants } from "../../utils/constants/yup";
import { NewEventForm } from "../../models/forms";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Event } from "../../models/events";
import {
  guestsTypes,
  spacesTypes,
} from "../../utils/constants/forms-constants";
import { InputFileCustom } from "..";
import { useGetEventTypesQuery } from "../../store/services/events-types";
import { parseDate } from "../../utils/functions/date";
interface EventFormProps {
  eventData?: Event;
  refetchEventData?: () => void;
}

export const EventForm = ({
  eventData = undefined,
  refetchEventData,
}: EventFormProps) => {
  const navigate = useNavigate();
  // const [userFullName, setuserFullName] = useState("");

  const { data: dataEventsTypes, isLoading: isLoadingTypes } =
    useGetEventTypesQuery();

  const [createEvent, { isLoading: isLoadingCreate }] =
    useCreateEventMutation();

  const [updateEvent, { isLoading: isLoadingUpdate }] =
    useUpdateEventMutation();

  const { isLoading: isLoadingEvents, refetch: refetchEvents } =
    useGetEventsQuery({});

  const goBack = () => navigate(-1);

  const now = new Date();
  const {
    handleSubmit,
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      id: eventData?.id || 0,
      name: eventData?.name,
      eventTypeId: eventData?.eventTypeId || "",
      eventSubTypeId: eventData?.eventSubTypeId || "",
      description: eventData?.description || "",
      guests: eventData?.guests || "",
      amount: eventData?.amount || "",
      price: eventData?.price || "",
      inscription: eventData?.inscription || "",
      duration: eventData?.duration || "",
      active: !!eventData?.active || true,
      startDate: eventData?.startDate
        ? new Date(eventData.startDate)
        : new Date(),
      address: eventData?.address || "",
      space: eventData?.space || "",
      rules: eventData?.rules || "",
      imageFormat: eventData?.imageFormat || "",
      imageBase64: eventData?.imageBase64 || "",
    } as NewEventForm,
    validationSchema: Yup.object({
      name: Yup.string().required(yupConstants().required),
      eventTypeId: Yup.string().required(yupConstants().required),
      eventSubTypeId: Yup.string().required(yupConstants().required),
      description: Yup.string().required(yupConstants().required),
      guests: Yup.number().required(yupConstants().required),
      amount: Yup.number().required(yupConstants().required),
      inscription: Yup.number().required(yupConstants().required),
      duration: Yup.date()
        .required(yupConstants().required)
        .typeError(yupConstants().required),
      startDate: Yup.date().required(yupConstants().required),
      address: Yup.string().required(yupConstants().required),
      space: Yup.string().required(yupConstants().required),
    }),
    onSubmit: (values) => {
      const {
        eventTypeId,
        eventSubTypeId,
        guests,
        amount,
        price,
        inscription,
        ...rest
      } = values;
      if (!!eventData) {
        updateEvent({
          ...rest,
          eventTypeId: parseInt(eventTypeId),
          eventSubTypeId: parseInt(eventSubTypeId),
          guests: parseInt(guests),
          amount: parseInt(amount),
          price: price ? parseInt(price) : null,
          inscription: parseInt(inscription),
        })
          .unwrap()
          .then(() => {
            toast.success("Evento modificado exitosamente!");
          })
          .catch(() => toast.error("No se pudo modificar el evento"))
          .finally(() => {
            refetchEvents();
            if (refetchEventData) refetchEventData();
            goBack();
          });
      } else {
        createEvent({
          ...rest,
          eventTypeId: parseInt(eventTypeId),
          eventSubTypeId: parseInt(eventSubTypeId),
          guests: parseInt(guests),
          amount: parseInt(amount),
          price: price ? parseInt(price) : null,
          inscription: parseInt(inscription),
        })
          .unwrap()
          .then(() => {
            toast.success("Evento creado exitosamente!");
          })
          .catch(() => toast.error("No se pudo crear el evento"))
          .finally(() => {
            refetchEvents();
            if (refetchEventData) refetchEventData();
            goBack();
          });
      }
    },
  });

  const handleInputFile = (value: string) => {
    let imageValues;

    if (value) {
      imageValues = value.split(",");
    } else {
      const defaultImagePath = "/Image-not-found.png";
      imageValues = ["image/png", defaultImagePath];
    }

    setFieldError("imageBase64", undefined);
    setFieldValue("imageFormat", imageValues[0]);
    setFieldValue("imageBase64", imageValues[1]);
  };
  const handleInputFileClear = () => {
    setFieldValue("imageFormat", "");
    setFieldValue("imageBase64", "");
  };

  const durationValue = values.duration ? parseDate(values.duration) : null;

  return (
    <form className="grid" onSubmit={handleSubmit}>
      <div className="col-12 md:col grid">
        <div className="col-12 my-2">
          <span className="p-float-label">
            <InputText
              invalid={!!touched.name && !!errors.name}
              className="w-full"
              id="name"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
            />
            <label htmlFor="name">Nombre</label>
          </span>
          {touched.name && errors.name && (
            <small className="p-error">{errors.name}</small>
          )}
        </div>
        <div className="col my-2">
          <span className="p-float-label">
            <Dropdown
              invalid={!!touched.eventTypeId && !!errors.eventTypeId}
              className="w-full"
              id="eventTypeId"
              name="eventTypeId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.eventTypeId}
              optionLabel="eventTypeName"
              optionValue="eventTypeId"
              options={dataEventsTypes}
              loading={isLoadingTypes}
              disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
            />
            <label htmlFor="eventTypeId">Tipo</label>
          </span>
          {touched.eventTypeId && errors.eventTypeId && (
            <small className="p-error">{errors.eventTypeId}</small>
          )}
        </div>
        <div className="col my-2">
          <span className="p-float-label">
            <Dropdown
              invalid={!!touched.eventSubTypeId && !!errors.eventSubTypeId}
              className="w-full"
              id="eventSubTypeId"
              name="eventSubTypeId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.eventSubTypeId}
              optionLabel="eventSubTypeName"
              optionValue="eventSubTypeId"
              options={[
                ...(dataEventsTypes?.find(
                  (item) => item.eventTypeId === parseInt(values.eventTypeId)
                )?.subTypes || []),
              ]}
              emptyMessage="Debe seleccionar un tipo"
              disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
            />
            <label htmlFor="eventSubTypeId">Sub-Tipo</label>
          </span>
          {touched.eventSubTypeId && errors.eventSubTypeId && (
            <small className="p-error">{errors.eventSubTypeId}</small>
          )}
        </div>
      </div>

      <div className="col-12 md:col-5 my-2 flex flex-column align-items-center justify-content-center">
        <InputFileCustom
          handleInputFile={handleInputFile}
          handleInputFileClear={handleInputFileClear}
          error={errors.imageBase64 && errors.imageBase64}
        />
      </div>

      <div className="col-12 my-2">
        <span className="p-label">
          <label htmlFor="description">Descripción</label>
          <InputTextarea
            className={`min-w-full ${
              touched.description && errors.description ? "p-invalid" : ""
            }`}
            id="description"
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            rows={3}
            autoResize
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
        </span>
        {touched.description && errors.description && (
          <small className="p-error">{errors.description}</small>
        )}
      </div>
      <div className="col-6 md:col-3 my-3">
        <span className="p-float-label">
          <Calendar
            required
            invalid={!!touched.startDate && !!errors.startDate}
            className="w-full"
            id="startDate"
            name="startDate"
            dateFormat="dd/mm/yy"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.startDate}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
            showTime
            hourFormat="24"
            minDate={now}
            hideOnDateTimeSelect
          />
          <label htmlFor="startDate">Fecha</label>
        </span>
        {touched.startDate && errors.startDate && (
          <small className="p-error">{yupConstants().required}</small>
        )}
      </div>
      <div className="col-6 md:col-3 my-3">
        <span className="p-float-label">
          <Calendar
            required
            invalid={!!touched.duration && !!errors.duration}
            className="w-full"
            id="duration"
            name="duration"
            onChange={handleChange}
            onBlur={handleBlur}
            value={durationValue}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
            showTime
            hourFormat="24"
            timeOnly
            hideOnDateTimeSelect
            readOnlyInput
          />
          <label htmlFor="duration">Duración</label>
        </span>
        {touched.duration && errors.duration && (
          <small className="p-error">{errors.duration}</small>
        )}
      </div>
      <div className="col-6 md:col-3 my-3">
        <span className="p-float-label">
          <InputText
            invalid={!!touched.amount && !!errors.amount}
            className="w-full"
            id="amount"
            name="amount"
            keyfilter="num"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
          <label htmlFor="amount">Cupos totales</label>
        </span>
        {touched.amount && errors.amount && (
          <small className="p-error">{errors.amount}</small>
        )}
      </div>
      <div className="col-6 md:col-3 my-3">
        <span className="p-float-label">
          <Dropdown
            className={`min-w-full ${
              touched.guests && errors.guests ? "p-invalid" : ""
            }`}
            id="guests"
            name="guests"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.guests}
            optionLabel="name"
            emptyFilterMessage="No options available"
            emptyMessage="No guests found"
            optionValue="value"
            options={[...guestsTypes]}
            loading={isLoadingTypes}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
          <label htmlFor="guest">Invitados por persona</label>
        </span>
        {touched.guests && errors.guests && (
          <small className="p-error">{errors.guests}</small>
        )}
      </div>

      <div className="col-12 md:col-6 my-2">
        <span className="p-float-label">
          <InputText
            invalid={!!touched.address && !!errors.address}
            className="w-full"
            id="address"
            name="address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
          <label htmlFor="address">Dirección</label>
        </span>
        {touched.address && errors.address && (
          <small className="p-error">{errors.address}</small>
        )}
      </div>
      <div className="col-12 md:col-6 my-2">
        <span className="p-float-label">
          <Dropdown
            className={`min-w-full ${
              touched.space && errors.space ? "p-invalid" : ""
            }`}
            id="space"
            name="space"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.space}
            optionLabel="name"
            optionValue="value"
            options={[...spacesTypes]}
            loading={isLoadingTypes}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
          <label htmlFor="space">Espacio</label>
        </span>
        {touched.space && errors.space && (
          <small className="p-error">{errors.space}</small>
        )}
      </div>
      <div className="col-12 my-2">
        <span className="p-label">
          <label htmlFor="rules">Reglas del evento</label>
          <InputTextarea
            className="w-full"
            id="rules"
            name="rules"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.rules}
            rows={3}
            autoResize
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
        </span>
      </div>
      <div className="col-12 md:col-6 my-2">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="price"
            name="price"
            prefix="$ "
            keyfilter="num"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.price}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
          <label htmlFor="price">Valor del evento</label>
        </span>
      </div>
      <div className="col-12 md:col-6 my-2">
        <span className="p-float-label">
          <InputText
            invalid={!!touched.inscription && !!errors.inscription}
            className="w-full"
            id="inscription"
            name="inscription"
            keyfilter="num"
            prefix="$ "
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.inscription}
            disabled={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
          />
          <label htmlFor="inscription">Valor de Inscripción</label>
        </span>
        {touched.inscription && errors.inscription && (
          <small className="p-error">{errors.inscription}</small>
        )}
      </div>
      <div className="col-12 my-2 flex justify-content-center align-content-center">
        <label htmlFor="active" className="mr-3">
          Activo
        </label>
        <InputSwitch
          id="active"
          checked={values.active}
          onChange={(e) => setFieldValue("active", e.value)}
        />
      </div>
      <div className="col-12 flex justify-content-center gap-4">
        <Button
          icon="pi pi-check"
          severity="success"
          type="submit"
          label={!!eventData ? "Actualizar" : "Crear"}
          loading={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
        />
        <Button
          icon="pi pi-times"
          severity="secondary"
          type="button"
          label={"Cancelar"}
          onClick={goBack}
          loading={isLoadingCreate || isLoadingUpdate || isLoadingEvents}
        />
      </div>
    </form>
  );
};

import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React, { useState } from "react";
import { resizeImage } from "../utils/functions/base64-functions";

interface InputFileCustomProps {
  handleInputFile: (value: string) => void;
  handleInputFileClear: () => void;
  error?: string;
  buttonText?: string;
}

export const InputFileCustom = ({
  handleInputFile,
  handleInputFileClear,
  error,
  buttonText = "Seleccionar imagen",
}: InputFileCustomProps) => {
  const [profileImage, setProfileImage] = useState<any | null>();

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (files) {
      resizeImage(files[0], 240, 480, (resizedImageData) => {
        setProfileImage(resizedImageData);
        handleInputFile(resizedImageData);
      });
    }
  };

  const handleClear = () => {
    setProfileImage(null);
    handleInputFileClear();
  };
  return (
    <>
      {profileImage ? (
        <Image src={profileImage} alt="profileImage" preview />
      ) : (
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var()",
            color: "var(--surface-d)",
          }}
        ></i>
      )}
      <input
        id="profileImage"
        name="profileImage"
        className="hidden"
        type="file"
        max={1}
        accept="image/*"
        onChange={handleFile}
      />
      {error && <small className="pt-2 p-error">{error}</small>}
      <div className="flex alig-items-center mt-4">
        <label
          htmlFor="profileImage"
          className={`flex align-items-center cursor-pointer border-1 border-round ${
            error && "border-red-900"
          }  text-primary px-4`}
        >
          {buttonText} <i className="pi pi-upload ml-2"></i>
        </label>
        <Button
          className="ml-2"
          icon="pi pi-trash"
          type="button"
          rounded
          text
          severity="danger"
          onClick={handleClear}
        />
      </div>
    </>
  );
};
